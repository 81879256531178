export default {
  iosUrl:
    "itms-services://?action=download-manifest&url=https://img.zhimi.live/app/livechat.plist",
  iosBackupUrl2:
    "itms-services://?action=download-manifest&url=https://img.zhimi.live/app/livechat2.plist",
  iosBackupUrl3:
    "itms-services://?action=download-manifest&url=https://img.zhimi.live/app/livechat3.plist",
  iosTf: "http://tf.yqd.mobi:808/download?sn=anan",
  androidUrl: "https://img.zhimi.live/app/anan.apk",
  androidUrl1: "https://img.zhimi.live/app/anan1.apk",
  apiDomain: "https://www.zhimi.live",
  ytIosBackupUrl:
    "itms-services://?action=download-manifest&url=https://img.zhimi.live/app/yitianlivechat.plist",
  ytIosBackupUrl2:
    "itms-services://?action=download-manifest&url=https://img.zhimi.live/app/yitianlivechat2.plist",
  ytIosTf: "http://tf.yqd.mobi:808/download?sn=yitian",
  ytAndroidUrl: "https://img.zhimi.live/app/yt.apk",
  ytAuditAndroidUrl: "https://img.zhimi.live/app/ytyt.apk",
  sharePrefix: "#zhimi#",
};
