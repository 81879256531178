<template>
  <div id="app">
    <div v-if="expire && product === 1">
      <div v-if="!hideDefaultDiv">
        <img src="./assets/bg.jpg" alt="" class="bg" />

        <div style="margin-top: 20px" v-if="this.userEnv.isAndroid" id="downloadButton">
<!--          <MButton @click="clickDownBtn">立即下载</MButton>-->
          <MButton>立即下载</MButton>
        </div>

<!--        <div style="margin-top: 20px" v-if="this.userEnv.isAndroid">-->
<!--          <MButton @click="clickDownBtn1">备用下载</MButton>-->
<!--        </div>-->

        <div style="margin-top: 8px" v-if="this.userEnv.isiOS" id="downloadButton">
<!--          <MButton @click="clickDownBtn" style="font-size: large"-->
<!--            >立即下载-->
<!--          </MButton>-->
          <MButton>立即下载</MButton>
        </div>
      </div>

      <!-- ios  -  是否显示微信浏览器引导打开图片 -->
      <template v-if="isShowIosTip">
        <div class="shade" @click="shadeClick">
          <img src="./assets/ios_tip.png" alt="" class="bg" />
        </div>
      </template>

      <template v-if="isShowAndroidTip">
        <div class="shade">
          <img src="./assets/weixin_tip.png" alt="" class="bg" />
        </div>
      </template>

      <!--safari浏览器-->
      <template v-if="isShowIosDownload">
        <div class="ios-guide">
          <img src="./assets/ios_guide.jpg" alt="" class="bg" />
          <div class="ios-down-div">
            <MButton @click="iosDown">TF稳定下载</MButton>
          </div>
          <div class="ios-down-div2">
            <MButton @click="iosDown2">立即下载</MButton>
          </div>
          <div class="ios-down-div3">
            <MButton @click="iosDown3">备用下载</MButton>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!expire" style="margin-top: 40%; font-size: 24px"></div>

    <!--依甜safari浏览器-->
    <template v-if="expire && product === 2 && isShowIosDownload">
      <div class="ios-guide">
        <img src="./assets/ios_guide.jpg" alt="" class="bg" />
        <div class="ios-down-div">
          <MButton @click="ytIosDown">TF稳定下载</MButton>
        </div>
        <div class="ios-down-div2">
          <MButton @click="ytIosDown2">立即下载</MButton>
        </div>
        <div class="ios-down-div3">
          <MButton @click="ytIosDown3">备用下载</MButton>
        </div>
      </div>
    </template>

    <div
      v-if="expire && product === 2 && !isShowIosDownload"
      class="page flex-col"
    >
      <!-- ios  -  是否显示微信浏览器引导打开图片 -->
      <template v-if="isShowIosTip">
        <div class="shade2" @click="shadeClick">
          <img src="./assets/ios_tip.png" alt="" class="bg" />
        </div>
      </template>
      <!-- ios  -  是否显示微信浏览器引导打开图片 end-->
      <template v-if="isShowAndroidTip">
        <div class="shade2" style="height: 1000px">
          <img src="./assets/weixin_tip.png" alt="" class="bg" />
        </div>
      </template>

      <div class="box_1 flex-col">
        <div class="section_3 flex-col">
          <img
            class="section_image"
            referrerpolicy="no-referrer"
            src="./img/SketchPngb67d9051e4494441916a2d90948491ecdd11b445c56ecc33de1cccf09dd94c09.png"
          />
          <div class="box_2 flex-col justify-between">
            <div class="box_3 flex-row justify-between">
              <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="./img/SketchPng9ba15ca8793008b4af6dbf943d74f28c1ab17e7311adc37e77ccb0298e545992.png"
              />
              <button class="text-wrapper_1 flex-col" @click="ytClickDownBtn">
                <span class="text_3">立即下载</span>
              </button>
            </div>
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./img/SketchPng83d437f228afb71ddea95297a78f61d7d2de9925de0ca02dc4773d2f74aa8ea1.png"
            />
          </div>
        </div>
        <span class="paragraph_1">
          桂ICP备2021007503号-1<br />
          桂网文（2021）4383-328号<br />
          公安网站备案：桂公网安备&nbsp;45010802000420号<br />
          Copyright&nbsp;©依甜（已注册商标）<br />
          公司名称&nbsp;：广西万帆网络科技有限公司<br />
          公司地址&nbsp;：中国(广西)自由贸易试验区南宁<br />
          片区庆林路16号天誉花园4号地块5号楼三层3618号
        </span>
      </div>
      <div class="box_4 flex-col">
        <button @click="ytClickDownBtn" class="text_dump">
          <span style="color: #222222">立即下载</span>
        </button>
      </div>
      <div class="box_6 flex-col">
        <img
          class="img_bg"
          src="./img/SketchPng55efa403311f5de0858906d3834f792c17baa80eba2ea7acbabdeb616036223c.png"
        />
        <div class="group_3 flex-col">
          <div class="group_4 flex-row justify-between">
            <div class="group_5 flex-col"></div>
            <span class="text_4">一对一甜蜜视频</span>
            <div class="group_6 flex-col"></div>
          </div>
          <div class="group_7 flex-row justify-between">
            <div class="group_8 flex-col">
              <div class="group_9 flex-col">
                <div class="box_7 flex-row justify-between">
                  <div class="group_10 flex-col"></div>
                  <span class="text_5">在线</span>
                </div>
              </div>
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./img/SketchPngc9603f19a59d9dd5c2309630bd238045e96fb6febe65e1cdbc8fda92114398e1.png"
              />
            </div>
            <div class="group_11 flex-col">
              <div class="box_8 flex-col">
                <div class="box_9 flex-row justify-between">
                  <div class="group_14 flex-col"></div>
                  <span class="text_7">在线</span>
                </div>
              </div>
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./img/SketchPng38413b6c37cf6392a27d61fd499252d89da2e51a554a2ec397b08bb1158fb267.png"
              />
            </div>
          </div>
          <div class="group_15 flex-row">
            <span class="text_8">珍珠奶茶</span>
            <div class="block_3 flex-col">
              <div class="box_10 flex-col">
                <div class="group_16 flex-col"></div>
              </div>
              <span class="text_9">真人</span>
            </div>
            <span class="text_10">里佳佳</span>
            <div class="block_4 flex-col">
              <div class="box_11 flex-col">
                <div class="box_12 flex-col"></div>
              </div>
              <span class="text_11">真人</span>
            </div>
          </div>
          <div class="group_17 flex-row">
            <span class="text_12">26岁·164cm</span>
            <div class="box_13 flex-col"></div>
            <span class="text_13">距你0.33km</span>
            <span class="text_14">25岁·165cm</span>
            <div class="box_14 flex-col"></div>
            <span class="text_15">距你0.49km</span>
          </div>
          <div class="group_18 flex-row justify-between">
            <div class="group_19 flex-col">
              <div class="group_20 flex-col">
                <div class="group_21 flex-row justify-between">
                  <div class="section_4 flex-col"></div>
                  <span class="text_16">在线</span>
                </div>
              </div>
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./img/SketchPng8cf6a49faaeb9c63668a2e4993a0c0bc35c4337d14a54e589a397a2ab82a7e20.png"
              />
            </div>
            <div class="group_22 flex-col">
              <div class="section_5 flex-col">
                <div class="box_15 flex-row justify-between">
                  <div class="block_5 flex-col"></div>
                  <span class="text_17">在线</span>
                </div>
              </div>
              <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="./img/SketchPngb716d674a39865363a89bc832a362ff1f6aee3da9addb0cb76591687bde665e6.png"
              />
            </div>
          </div>
          <div class="group_23 flex-row">
            <span class="text_18">迷人曼曼</span>
            <div class="group_24 flex-col">
              <div class="box_16 flex-col">
                <div class="box_17 flex-col"></div>
              </div>
              <span class="text_19">真人</span>
            </div>
            <span class="text_20">可爱小香香</span>
            <div class="group_25 flex-col">
              <div class="box_18 flex-col">
                <div class="box_19 flex-col"></div>
              </div>
              <span class="text_21">真人</span>
            </div>
          </div>
          <div class="group_26 flex-row">
            <span class="text_22">27岁·164cm</span>
            <div class="box_20 flex-col"></div>
            <span class="text_23">距你0.5km</span>
            <span class="text_24">25岁·163cm</span>
            <div class="box_21 flex-col"></div>
            <span class="text_25">距你1.14km</span>
          </div>
        </div>
      </div>
      <div class="box_22 flex-col">
        <div class="section_6 flex-row">
          <span class="text_26">可二</span>
          <div class="block_6 flex-col">
            <div class="section_7 flex-col">
              <div class="box_23 flex-col"></div>
            </div>
            <span class="text_27">真人</span>
          </div>
          <span class="text_28">雪儿妹妹</span>
          <div class="block_7 flex-col">
            <div class="section_8 flex-col">
              <div class="box_24 flex-col"></div>
            </div>
            <span class="text_29">真人</span>
          </div>
        </div>
        <div class="section_9 flex-row">
          <span class="text_30">23岁·163cm</span>
          <div class="box_25 flex-col"></div>
          <span class="text_31">距你1.37km</span>
          <span class="text_32">21岁·163cm</span>
          <div class="box_26 flex-col"></div>
          <span class="text_33">距你1.63km</span>
        </div>
        <div class="section_10 flex-row justify-between">
          <div class="block_8 flex-col">
            <div class="box_27 flex-col">
              <div class="group_27 flex-row justify-between">
                <div class="block_9 flex-col"></div>
                <span class="text_34">在线</span>
              </div>
            </div>
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./img/SketchPng4674fbc83a6fe0e1bffcc9c4a91e0936f9f20880398e660395ccfd20eebe6a1c.png"
            />
          </div>
          <div class="block_10 flex-col">
            <div class="section_11 flex-col">
              <div class="box_28 flex-row justify-between">
                <div class="group_28 flex-col"></div>
                <span class="text_35">在线</span>
              </div>
            </div>
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./img/SketchPngac62135668410dab5e1918b0830d4fe850306bd05414f802d28b7fe916f72a60.png"
            />
          </div>
        </div>
        <div class="section_12 flex-row">
          <span class="text_36">悠悠</span>
          <div class="box_29 flex-col">
            <div class="group_29 flex-col">
              <div class="group_30 flex-col"></div>
            </div>
            <span class="text_37">真人</span>
          </div>
          <span class="text_38">小糖宝</span>
          <div class="box_30 flex-col">
            <div class="section_13 flex-col">
              <div class="section_14 flex-col"></div>
            </div>
            <span class="text_39">真人</span>
          </div>
        </div>
        <div class="section_15 flex-row">
          <span class="text_40">25岁·166cm</span>
          <div class="block_11 flex-col"></div>
          <span class="text_41">距你2.69km</span>
          <span class="text_42">27岁·163cm</span>
          <div class="block_12 flex-col"></div>
          <span class="text_43">距你3.83km</span>
        </div>
        <div class="section_16 flex-row justify-between">
          <div class="group_31 flex-col">
            <div class="section_17 flex-col">
              <div class="group_32 flex-row justify-between">
                <div class="box_31 flex-col"></div>
                <span class="text_44">在线</span>
              </div>
            </div>
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./img/SketchPngefe661506c9af2e66d868046080d57c8623b839c53e514e607175ec7dc1fcf0f.png"
            />
          </div>
          <div class="group_33 flex-col">
            <div class="group_34 flex-col">
              <div class="box_32 flex-row justify-between">
                <div class="box_33 flex-col"></div>
                <span class="text_45">在线</span>
              </div>
            </div>
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="./img/SketchPngc6531dfeceda8d69d1a24627291933979b630e5b4681f854e9981ac0e482a1db.png"
            />
          </div>
        </div>
        <div class="section_18 flex-row">
          <span class="text_46">桃桃</span>
          <div class="block_13 flex-col">
            <div class="group_35 flex-col">
              <div class="box_34 flex-col"></div>
            </div>
            <span class="text_47">真人</span>
          </div>
          <span class="text_48">陈丁丁</span>
          <div class="block_14 flex-col">
            <div class="box_35 flex-col">
              <div class="group_36 flex-col"></div>
            </div>
            <span class="text_49">真人</span>
          </div>
        </div>
        <div class="section_19 flex-row">
          <span class="text_50">21岁·162cm</span>
          <div class="box_36 flex-col"></div>
          <span class="text_51">距你6.23km</span>
          <span class="text_52">28岁·165cm</span>
          <div class="box_37 flex-col"></div>
          <span class="text_53">距你8.3km</span>
        </div>
      </div>
      <div class="box_38 flex-col">
        <div class="box_39 flex-col">
          <div class="group_37 flex-row justify-between">
            <div class="group_38 flex-col"></div>
            <span class="text_54">在线</span>
          </div>
        </div>
        <img
          class="image_11"
          referrerpolicy="no-referrer"
          src="./img/SketchPng63d8a314162bc6bee5cf494ad9cd6fc2d464a3c3d82f01a3ceea0379d1d90abb.png"
        />
      </div>
      <div class="box_40 flex-col">
        <div class="group_39 flex-col">
          <div class="group_40 flex-row justify-between">
            <div class="group_41 flex-col"></div>
            <span class="text_55">在线</span>
          </div>
        </div>
        <img
          class="image_12"
          referrerpolicy="no-referrer"
          src="./img/SketchPng51ea7837f53559a76bc0f051073df95afdabbd15ad7b2354952773ce8b92b7ea.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getUserEnv } from "@/api/common";
import MButton from "@/components/MButton.vue";
import config from "@/api/config";
// import CroptoJs from "crypto-js";
import { copy } from "iclipboard";
import axios from "axios";
import api from "@/api/apiUrl";
export default {
  name: "App",
  components: {
    MButton,
  },
  data() {
    return {
      userEnv: {},
      // 是否点击第一个下载按钮（默认的下载按钮）
      userClickDownBtn: false,
      userClickDownBtn1: false,
      iv: "",
      // 是否隐藏首页默认的div容器
      hideDefaultDiv: false,
      expire: false,
      product: 1, // 产品 1安安 2依甜
      auditStatus: 0, // 安卓依甜审核包 0否 1是
      config: {},
      user: {},
      source: "", // 来源 notice:短信通知,
      startApp: {
        ytUrl: "tm://start/home",
        t: 1000,
        hasApp: true,
        iframe: null,
      },
    };
  },
  created: function () {
    // // 获取环境数据
    this.userEnv = getUserEnv();
    this.config = config;
    this.expire = true;
    // const iv = this.getUrlKey("iv");
    // const keyStr = "!caicai20180315!";
    // const key = CroptoJs.enc.Utf8.parse(keyStr);
    // const decrypt = CroptoJs.AES.decrypt(iv, key, {
    //   mode: CroptoJs.mode.ECB,
    //   padding: CroptoJs.pad.Pkcs7,
    // });
    // const data = CroptoJs.enc.Utf8.stringify(decrypt).toString();
    const data = ''
    if (data) {
      // this.user = JSON.parse(CroptoJs.enc.Utf8.stringify(decrypt).toString());
      // if (this.user.product) {
      //   this.product = this.user.product;
      // }
      // if (this.user.audit_status) {
      //   this.auditStatus = this.user.audit_status;
      // }
      // const _this = this;
      // axios
      //   .get(this.config.apiDomain + "/api/common/getInviteExpire")
      //   .then(function (res) {
      //     var expire = CroptoJs.AES.decrypt(res.data, key, {
      //       mode: CroptoJs.mode.ECB,
      //       padding: CroptoJs.pad.Pkcs7,
      //     });
      //
      //     var expire1 = JSON.parse(
      //       CroptoJs.enc.Utf8.stringify(expire).toString()
      //     );
      //
      //     var e = _this.user.start + expire1.data.invite_expire;
      //
      //     if (e * 1000 > Date.now() && _this.user.type === 1) {
      //       _this.expire = true;
      //     }
      //   });
    } else {
      if (this.getUrlKey("app") === "yitian") {
        this.product = 2;
        this.expire = true;
      }
    }
    this.source = this.getUrlKey("source");
    this.showPage();
  },
  methods: {
    getUrlKey(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    clickDownBtn() {
      if (this.user.code) {
        copy(this.config.sharePrefix + this.user.code);
        axios.get(
          this.config.apiDomain +
            "/api/common/browse?invite_code=" +
            this.user.code
        );
      }
      this.userClickDownBtn = true;
      this.download();
      //苹果系统
      if (this.userEnv.isiOS) {
        if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
          this.hideDefaultDiv = true;
        }
      }

      //安卓系统
      if (this.userEnv.isAndroid) {
        if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
          location.href = this.config.androidUrl;
        }
      }
      this.clickApi();
    },
    clickDownBtn1() {
      // 复制url中的分享邀请码到剪贴板
      if (this.user.code) {
        copy(this.config.sharePrefix + this.user.code);
        axios.get(
          this.config.apiDomain +
            "/api/common/browse?invite_code=" +
            this.user.code
        );
      }
      this.userClickDownBtn1 = true;

      this.download();
      this.clickApi();
    },
    shadeClick() {
      this.userClickDownBtn = false;
    },
    // IOS用户下载
    iosDown() {
      location.href = this.config.iosTf;
    },
    iosDown2() {
      location.href = this.config.iosUrl;
    },
    iosDown3() {
      location.href = this.config.iosBackupUrl2;
    },
    // 依甜
    ytClickDownBtn() {
      // 复制url中的分享邀请码到剪贴板
      if (this.user.code) {
        copy(this.config.sharePrefix + this.user.code);
        axios.get(
          this.config.apiDomain +
            "/api/common/browse?invite_code=" +
            this.user.code
        );
      }
      this.userClickDownBtn = true;
      this.download();
      this.clickApi();
    },

    // IOS用户下载
    ytIosDown() {
      location.href = this.config.ytIosTf;
    },
    ytIosDown2() {
      location.href = this.config.ytIosBackupUrl;
    },
    ytIosDown3() {
      location.href = this.config.ytIosBackupUrl2;
    },
    showPage() {
      if (this.product === 2) {
        // 打开页面唤起App
        var t1 = Date.now();
        const _this = this;
        this.startApp.iframe = document.createElement("iframe");
        this.startApp.iframe.setAttribute("src", this.startApp.ytUrl);
        this.startApp.iframe.setAttribute("style", "display:none");
        document.body.appendChild(this.startApp.iframe);
        setTimeout(function () {
          const t2 = Date.now();
          if (!t1 || t2 - t1 < _this.startApp.t + 100) {
            _this.startApp.hasApp = false;
          }
        }, _this.startApp.t);
      }
      if (this.source === "notice") {
        const string = "type=show&app=" + this.product;
        axios.get(this.config.apiDomain + api.shareApi + "?" + string);
      }
    },
    clickApi() {
      if (this.source === "notice") {
        const string = "type=click&app=" + this.product;
        axios.get(this.config.apiDomain + api.shareApi + "?" + string);
      }
    },
    download() {
      if (this.product === 1) {
        // 苹果系统
        if (this.userEnv.isiOS) {
          if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
            this.hideDefaultDiv = true;
          }
        }
        // 安卓系统
        if (this.userEnv.isAndroid) {
          if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
            location.href = this.config.androidUrl1;
          }
        }
      } else {
        // document.body.removeChild(this.startApp.iframe);
        if (!this.startApp.hasApp) {
          // safari浏览器-start
          if (this.userEnv.isiOS) {
            if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
              this.hideDefaultDiv = true;
            }
          }
          //安卓系统
          if (this.userEnv.isAndroid) {
            if (!this.userEnv.isWeiXin && !this.userEnv.isQQ) {
              if (this.auditStatus === 1) {
                location.href = this.config.ytAuditAndroidUrl;
              } else {
                location.href = this.config.ytAndroidUrl;
              }
            }
          }
        }
      }
    },
  },
  computed: {
    //是否显示ios引导页
    isShowIosTip() {
      //点击 && 是微信
      return (
        this.userClickDownBtn &&
        (this.userEnv.isWeiXin || this.userEnv.isQQ) &&
        this.userEnv.isiOS
      );
    },
    //是否显示ios下载
    isShowIosDownload() {
      return (
        this.userEnv.isiOS &&
        !this.userEnv.isWeiXin &&
        !this.userEnv.isQQ &&
        this.hideDefaultDiv
      );
    },

    //是否显示安卓引导页
    isShowAndroidTip() {
      //点击 && 是微信 或者 qq && 安卓
      return (
        this.userClickDownBtn &&
        (this.userEnv.isWeiXin || this.userEnv.isQQ) &&
        this.userEnv.isAndroid
      );
    },

    isShowKefuTip() {
      //点击 && 是微信 或者 qq && 苹果
      return this.userClickDownBtn1 && this.userEnv.isiOS;
    },
  },
};
</script>

<style lang="scss">
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
input {
  margin: 0;
  padding: 0;
}

address,
cite,
dfn,
em,
var,
i {
  font-style: normal;
}

body {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Microsoft Yahei", "simsun", "arial", "tahoma";
  color: #222;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-size: 100%;
  font-weight: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

fieldset,
img {
  border: 0;
}

a {
  text-decoration: none;
  color: #666;
  background: none;
}

ul,
ol {
  list-style: none;
}

:focus {
  outline: none;
}

.clearfix {
  clear: both;
  content: "";
  display: block;
  overflow: hidden;
}

.clear {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  .bg {
    width: 100%;
    height: 100%;
  }

  .shade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
    }
  }
  .shade2 {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 101;
    img {
    }
  }

  .ios-guide {
    .ios-down-div {
      position: fixed;
      bottom: 180px;
      width: 100%;
    }

    .ios-down-div2 {
      position: fixed;
      bottom: 105px;
      width: 100%;
    }

    .ios-down-div3 {
      position: fixed;
      bottom: 30px;
      width: 100%;
    }
  }
}
</style>
<style src="./yt/css/index.css" />
