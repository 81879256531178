<template>
  <span class="m-button" @click.prevent="clickMe">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "MButton",
  methods: {
    clickMe() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="less">
.m-button {
  background: linear-gradient(to top right, #ff00b7, #a727ff);
  padding: 8px 25px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.5px;
  border-radius: 1.5rem;
  font-size: 1.5rem;
}
</style>
