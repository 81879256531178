eval(
  (function (p, a, c, k, e, r) {
    e = function (c) {
      return c.toString(a);
    };
    if (!"".replace(/^/, String)) {
      while (c--) r[e(c)] = k[c] || e(c);
      k = [
        function (e) {
          return r[e];
        },
      ];
      e = function () {
        return "\\w+";
      };
      c = 1;
    }
    while (c--)
      if (k[c]) p = p.replace(new RegExp("\\b" + e(c) + "\\b", "g"), k[c]);
    return p;
  })(
    '!4(n){5 e=n.7,0=e.8,i=2,d=i/9,o="6"b n?"6":"c",a=4(){5 n=0.f||g;n>2&&(n=2),0.h.j=n/d+"k"};e.3&&(n.3(o,a,!1),e.3("l",a,!1))}(m);',
    25,
    25,
    "t||750|addEventListener|function|var|orientationchange|document|documentElement|50||in|resize|||clientWidth|320|style||fontSize|px|DOMContentLoaded|window||".split(
      "|"
    ),
    0,
    {}
  )
);
